<template>
  <div>
    <div
      class="p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img
        src="@/assets/img/referral-completed.png"
        width="500"
        alt="referral-completed-icon"
      />
      <h2 class="mt-5 pb-2">Referral Created!</h2>

      <p class="w-50 text-center">
        Referral successfully created, please give our doctors, admins and
        nurses time to complete their part. You will be emailed once all
        progress is completed.
      </p>
      <router-link
        :to="{ name: 'assessment-list' }"
        class="nav-link align-start px-0 mt-5"
      >
        <button type="button" class="btn bg-primary px-3 text-white">
          Bring me back to RTWSA list
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
